import Head from 'next/head';

type CustomHeadProps = {
  title: string;
  description?: string;
  canonicalUrl?: string;
  children?: React.ReactNode;
};

const CustomHead = ({
  title,
  description,
  canonicalUrl,
  children,
}: CustomHeadProps) => {
  return (
    <Head>
      <title>{title} | Belmondofoto</title>
      <meta name="description" content={description || ''} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://belmondofoto.nl/images/m/facebook.jpg"
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content={title} />
      <meta property="og:url" content={canonicalUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://belmondofoto.nl/images/m/twitter.jpg"
      />
      <meta name="twitter:image:alt" content="website cover" />
      {children}
    </Head>
  );
};

export default CustomHead;

import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import {
  backgroundColored,
  backgroundColoredMedium,
  cta,
  ctaOutline,
  fontDark,
} from '@constants/colors';
import { laptop, tablet } from '@constants/media-queries';
import { reviews } from '@constants/reviews-data';
import { googleReviews } from '@constants/routes';
import { containerWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Text from '@elements/Text';
import Title from '@elements/Title';
import useMediaQuery from '@hooks/useMediaQuery';

const Component = styled.section`
  background-color: ${backgroundColored};
  padding: ${space * 6}px ${space * 3}px;

  ${tablet} {
    padding: ${space * 6}px ${space * 2}px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${containerWidth};

  > a {
    margin-top: ${space * 3}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTitle = styled(Title)`
  text-align: center;
  color: ${fontDark};
`;

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${space * 2}px;
`;

const Review = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space * 3}px;
  list-style: none;
  background: ${backgroundColoredMedium};
  border-radius: 24px;
  max-width: 370px;
  flex-basis: 100%;

  ${laptop} {
    flex-basis: calc(33.3333% - 32px);
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
`;

type ImageContainerProps = {
  width: number;
};

const ImageContainer = styled.div<ImageContainerProps>`
  width: ${({ width }) => width}px;
`;

const Body = styled(Text)`
  color: ${fontDark};
  text-align: center;
`;

const TextLink = styled.a`
  color: ${cta};
  font-weight: 500;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const Reviews = () => {
  const { isTablet } = useMediaQuery();
  const reviewsToDisplay = isTablet ? reviews.slice(-2) : reviews;

  return (
    <Component>
      <Container>
        <StyledTitle size="lg" className="mb-40" as="h2">
          Wat onze klanten zeggen
        </StyledTitle>
        <Wrapper>
          {reviewsToDisplay.map((review, i) => {
            return (
              <Review key={i}>
                <Text size="sm" weight={600}>
                  {review.author.fistName}
                </Text>
                <Rating className="mb-8">
                  <ImageContainer width={95}>
                    <Image
                      src={`/images/reviews/${review.stars}`}
                      alt="Rating"
                      width={320}
                      height={50}
                    />
                  </ImageContainer>
                </Rating>
                <Body size="nm" className="mb-16">
                  {review.text}
                </Body>
                <ImageContainer width={20}>
                  <Image
                    src={`/images/reviews/${review.logo}`}
                    alt="logo reviews"
                    width={20}
                    height={20}
                    loading="lazy"
                  />
                </ImageContainer>
              </Review>
            );
          })}
        </Wrapper>
        <TextLink>
          <a href={googleReviews} target="_blank" rel="noreferrer">
            Lees alle reviews
            <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </TextLink>
      </Container>
    </Component>
  );
};

export default Reviews;

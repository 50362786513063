import { useRouter } from 'next/router';
import { ReactNode } from 'react';

type Props = {
  targetId: string;
  pagePath: string;
  offset?: number;
  children: ReactNode;
};

const ScrollLink = ({ targetId, pagePath, offset = 0, children }: Props) => {
  const router = useRouter();

  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();

    await router.push(pagePath);

    setTimeout(() => {
      const element = document.getElementById(targetId);
      if (element) {
        const yPosition =
          element.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <a href={`${pagePath}#${targetId}`} onClick={handleClick}>
      {children}
    </a>
  );
};

export default ScrollLink;

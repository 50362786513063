export const reviews = [
  {
    stars: 'star_rating_5.png',
    text: 'Het was een feest om de boeken voor het huwelijk van mijn broer en schoonzus te maken.',
    author: {
      fistName: 'Nina',
    },
    logo: 'trustpilot_logo_small.png',
  },
  {
    stars: 'star_rating_5.png',
    text: `Fotoboek laten maken voor de 30e  verjaardag van m'n zus. Super mooi geworden, top kwaliteit!`,
    author: {
      fistName: 'Bart',
    },
    logo: 'trustpilot_logo_small.png',
  },
  {
    stars: 'star_rating_5.png',
    text: 'Ik heb het fotoboek binnen!! En ik wil niet opscheppen of overdrijven. Ja, toch wel. Hij is echt geweldig!!!',
    author: {
      fistName: 'Conchita',
    },
    logo: 'trustpilot_logo_small.png',
  },
];

import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import {
  backgroundColoredMedium,
  brand,
  cta,
  ctaOutline,
  fontDark,
  fontMedium,
} from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { prices } from '@constants/routes';
import { contentWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Badge from '@elements/Badge';
import Text from '@elements/Text';
import Title from '@elements/Title';

import ScrollLink from './ScrollLink';

const Component = styled.section`
  padding: ${space * 5}px ${space * 4}px;
  text-align: center;
  color: ${fontDark};
  background-color: ${backgroundColoredMedium};

  ${tablet} {
    padding: ${space * 8}px ${space * 4}px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
`;

const ImgContainer = styled.div`
  flex-basis: 100%;
  ${tablet} {
    flex-basis: 50%;
  }
`;

const StyledText = styled(Text)`
  color: ${fontMedium};

  span {
    padding: 0 4px;
    opacity: 0.5;
  }
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${space * 3}px;
`;

const StyledBadge = styled(Badge)`
  border-radius: 8px;
  background-color: ${brand};
  font-weight: 600;
  color: white;
  font-size: 12px;
  padding: ${space / 4}px ${space}px;
  cursor: pointer;
`;

type BookTypesProps = {
  main?: boolean;
  buttonText?: string;
  buttonLink?: string;
};

const BookTypes = ({ main, buttonText, buttonLink }: BookTypesProps) => {
  return (
    <Component>
      <Container>
        <Info>
          <ScrollLink pagePath="/prijzen" targetId="levertijden" offset={-100}>
            <StyledBadge>Verzonden binnen 5 werkdagen!</StyledBadge>
          </ScrollLink>
        </Info>
        {main ? (
          <Title size="lg" weight={800} className="mb-8" as="h1">
            Prijzen & levertijd
          </Title>
        ) : (
          <Title size="lg" weight={800} className="mb-8" as="h2">
            Kies zelf het formaat
          </Title>
        )}
        <Text size="nm" className="mb-24">
          Pak groots uit of geef een handzaam cadeau. Je kiest pas als je het
          boek bestelt.
        </Text>
        <Link href={buttonLink || prices} passHref>
          <StyledLink>
            {buttonText || 'Bekijk prijzen'}
            <FontAwesomeIcon icon={faArrowRightLong} />
          </StyledLink>
        </Link>
        <ImgContainer className="mt-24">
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/fotoboek_formaten_8040bWWH5.jpg?updatedAt=1715959793834&ik-s=77dd81cbea33bd4fbcad399a5ddb8a66745397cd"
            alt="Boek formaten belmondofoto"
            width={740}
            height={330}
            unoptimized={true}
            loading="lazy"
          />
        </ImgContainer>
        {main && (
          <>
            <StyledText size="nm" className="mt-24">
              24-400 pagina&apos;s <span>●</span> Vanaf €0,50 per extra pagina
            </StyledText>
            <StyledText size="nm" className="mt-8">
              Verzendkosten € 3,50 <span>(Nederland)</span>
            </StyledText>
          </>
        )}
      </Container>
    </Component>
  );
};

export default BookTypes;
